<template>
    <Layout>
        <div class="errorPage">
            <div class="internalServerError">
                <div class="internalServerError__contentWrapper">
                    <div class="internalServerError__buttonBox">
                        <p class="h1">Ошибка! Что-то пошло не так</p>
                        <Button
                            class="internalServerError__buttonBox_button"
                            title="на главную"
                            :isLink="true"
                            :link="'/'"
                        />
                    </div>
                    <p class="colorZircon internalServerError__text">500</p>
                </div>
                <div class="internalServerError__contentWrapper">
                    <img alt="500" src="@/assets/img/pages/utility/500.png" />
                    <div class="internalServerError__frame">
                        <p class="text4 colorGray internalServerError__frameText">
                            Не ошибается тот, кто ничего не делает. Не бойтесь ошибаться —
                            бойтесь повторять ошибки.<br />
                            (с) Т. Рузвельт
                        </p>
                        <img
                            class="internalServerError__img"
                            alt="arrow"
                            src="@/assets/img/pages/utility/arrow.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import Button from "@/views/components/Button/Button";
export default {
    name: "500",

    components: {
        Layout,
        Button,
    },
};
</script>

<style lang="scss" scoped>
@import "500.scss";
</style>
